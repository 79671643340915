import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Footer from '../../components/footer';
import NavBar from '../../components/navbar';
import Curso from '../../data/curso';
import inMemoryJwt from '../../data/inMemoryJwt';
import { store } from '../../store/userStore';
import CardAssociado from '../listaassociados/components/cardassociado';
import CardInscrito from './components/cardinscrito';


function ListaInscritosCursoScreen() {
    const [statusPagina, setStatusPagina] = useState(0);
    const [listaInscritos, setListaInscritos] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [logado,setLogado] = useState(0);
    var state = store.getState();
    const logado2 = useSelector(state => state.user.hora);
    let { id } = useParams();
    useEffect(() => {
        setStatusPagina(1);
        var cursos = new Curso();
        cursos.getlistaInscritosCursoById(id, state.user.id2, inMemoryJwt.getToken()).then((info) => {
            setTitulo(info.titulo);
            setListaInscritos(info.lista);
        });
        setStatusPagina(0);
    }, [logado2]);

    useEffect(()=>{
        
        if (inMemoryJwt.getToken()){
            setLogado(1);
            
        } else{
            setLogado(0);
        }
        
    },[logado2]);

    return (
        <div className='row'>
            <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
            <div className="col-10 p-0">
                <NavBar />
                <div className="row justify-content-center p-4">
                    <div className="container mt-4 align-items-center">
                        {
                            logado === 0 ?
                            <div>
                                <h3>Caro usuário você precisa refazer seu login para acessar esta lista.</h3>
                            </div>
                            :
                            statusPagina === 0 ?
                                <>
                                    <div className='container'>
                                        <h3>{titulo}</h3>
                                        <h5>{"Total de Inscritos: " + listaInscritos.length}</h5>
                                        <CardAssociado
                                            nome="Nome"
                                            telefone="Telefone"
                                            email="e-mail"
                                            index={-3}
                                        />
                                        {
                                            listaInscritos.map((inscrito, index) => <CardAssociado
                                                nome={inscrito.nome}
                                                telefone={inscrito.telefone}
                                                email={inscrito.email}
                                                index={index}
                                            />)
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    
                                </>
                        }
                    </div>
                    <Footer/>
                </div>
            </div>
            <div className="col-sm-1" id="ladodireito"></div>
        </div>
    );
}

export default ListaInscritosCursoScreen;