import "./inicial2.css"
import Navbar from "../../components/navbar";
import ex_noticia1 from "../../image/ex_noticias1.png";
import ex_noticia2 from "../../image/ex_noticias2.png";
import ex_noticia3 from "../../image/ex_noticias3.jpeg";
import logo2 from "../../image/logo2.png"
import sergipe from "../../image/Sergipe.png"
import quem_somos from "../../image/tela_inicial_quemsomos.png";
import CarrosselNoticias from "../../components/carrosselnoticias";
import CarrosselNoticias2 from "../../components/carrroselnoticias2";
import CarrosselCursos from "../../components/carroselcursos";
import ListaNoticiasInicial from "../../components/listanoticiasinicial";
import ListaCursoInicial from "../../components/listacursoinicial";
import Footer from "../../components/footer";
function TelaInicial2() {

    return (

        <>
            <div className="row">
                <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
                <div className="col-sm-10 p-0">
                    <div className="card">
                        
                        <div className="col-sm-12 mt-0">
                            <Navbar />
                        </div>
                        <div className="row justify-content-start mt-5 mb-5 p-1">

                            <div className="col-sm-6 ms-2">
                                <img src={quem_somos} width="100%"/>
                            </div>
                            <div className="col-sm-5 ms-2">
                            <h2>Quem Somos</h2>
                                <p id="txt-quemsomos">A natureza e a finalidade da ABEn conferem-lhe caráter cultural, científico
                                    e político. Pautada em princípios éticos e legais e, em conformidade com suas
                                    competências, a ABEn articula-se a organizações nacionais e internacionais para
                                    desenvolvimento profissional, científico, social, político e cultural da
                                    Enfermagem brasileira.
                                </p>
                            </div>

                        </div>
                        <hr id="divisao-hr"/>
                        <div className="row justify-content-start mt-5 mb-1 p-1" id="linha-noticias">

                            <div className="col-12 carrossel">
                                <h2 className="text-center" id="noticias-titulo">Últimas Notícias</h2>
                                <ListaNoticiasInicial />
                            </div>
                            

                        </div>
                        <hr id="divisao-hr"/>
                        <div className="row justify-content-start mt-1 mb-5 p-1" id="linha-noticias">

                            
                            <div className="col-12" >
                                <h2 className="text-center" id="cursos-titulo">Cursos & Eventos</h2>
                                <ListaCursoInicial/>
                            </div>

                        </div>
                        <Footer/>
                    </div>
                </div>
                <div className="col-sm-1" id="ladodireito"></div>

            </div>
        </>
    );
}

export default TelaInicial2;