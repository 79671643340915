import './trocarsenha.css';
import React, { useState } from 'react';
import Navbar from '../../components/navbar';
import User from '../../data/user';
import { store } from '../../store/userStore.js';
import ModalAviso from '../../components/avisos';

function TrocarSenhaScreen() {


    const [senhaAntiga, setSenhaAntiga] = useState('');
    const [senhaNova, setSenhaNova] = useState('');
    const [senhaNova2, setSenhaNova2] = useState('');
    const [senhasDiferentes, setSenhaDiferente] = useState();
    const [msgModal, setMsgModal] = useState('');
    const [tituloModal, setTituloModal] = useState('');

    const [modalShow, setModalShow] = useState(false);
    const [enviando, setEnviando] = useState(false);


    const erro = {
        senha: '',
        novaSenha: '',
        novaSenha2: '',
    }
    const [erros, setErros] = useState(erro);

    function validar() {
        erro.senha = '';
        erro.novaSenha = '';
        erro.novaSenha2 = '';
        var valido = true;
        if (senhaAntiga == null || senhaAntiga == '') {
            erro.senha = 'Preencha a senha atual';
            valido = false;
        }

        if (senhaNova == null || senhaNova == '') {
            erro.novaSenha = 'Preencha a nova senha';
            valido = false;
        } else {
            if (senhaNova !== senhaNova2) {
                erro.novaSenha = 'Senhas são diferentes';
                valido = false;
            }
        }

        setErros(erro);
        return valido;
    }

    function compararSenha(senhatemp) {
        var senha2 = '';
        senha2 = senhaNova;
        var senhatemp2 = '';
        senhatemp2 = senhatemp;
        if (senha2 == null) {
            return;
        }
        if (senhatemp2 !== senha2.substring(0, senhatemp2.length)) {
            setSenhaDiferente(1);
        }
        else {
            setSenhaDiferente(0);
        }
        setSenhaNova2(senhatemp);
    }

    async function trocarSenha() {
        setEnviando(true);
        if (validar()) {
            var user = new User();
            const state = store.getState();
            user.id = state.user.id;
            const resultado = await user.trocarSenha(senhaAntiga, senhaNova);
            if (resultado === 1) {
                setTituloModal('Aviso');
                setMsgModal('Senha trocada com sucesso.');
                setModalShow(true);
            } else if (resultado == -1) {
                setTituloModal('Aviso');
                setMsgModal('Sua senha antiga está errada, por favor verifique sua senha e tente novamente.');
                setModalShow(true);
            }
        } else {
            setTituloModal('Aviso');
            setMsgModal('Houve uma falha no envio da sua proposta por favor verique os itens que faltaram.');
            setModalShow(true);
        }
        setEnviando(false);
    }

    return (
        <>

            <div className="">
                <Navbar/>
                <div className="row mt-5">
                    <div className="col-sm-4 d-none d-sm-block"></div>
                    
                    <div className="col-sm-4">
                    <div className="container align-items-center">
                        <h1 className="text-center txt-roxo">Trocar Senha</h1>
                        <div className="form-signin">
                            <div className="form-label-group">
                                <label htmlFor="inputSenhaAtual">Senha Antiga</label>
                                <input id="inputSenhaAtual" className="form-control txt-roxo" placeholder="Senha Antiga" required="" autoFocus="" type="password" onChange={(e) => setSenhaAntiga(e.target.value)} />
                            </div>
                            {erros.senha !== '' ?
                                <> <div className="alert alert-danger" role="alert">
                                    {erros.senha}
                                </div> </>
                                :
                                <></>
                            }
                            <div className="form-label-group">
                                <label htmlFor="inputSenhaNova">Nova Senha</label>
                                <input id="inputSenhaNova" className="form-control txt-roxo" placeholder="Nova Senha" required="" autoFocus="" type="password" onChange={(e) => setSenhaNova(e.target.value)} />

                            </div>
                            {erros.novaSenha !== '' ?
                                <> <div className="alert alert-danger" role="alert">
                                    {erros.novaSenha}
                                </div> </>
                                :
                                <></>
                            }
                            <div className="form-label-group">
                                <label htmlFor="inputSenhaNova2">Repita Nova Senha</label>
                                <input id="inputSenhaNova2" className="form-control txt-roxo" placeholder="Nova Senha" required="" autoFocus="" type="password" onChange={(e) => compararSenha(e.target.value)} />

                            </div>
                            {(senhasDiferentes === 1) ?
                                <> <div className="alert alert-danger" role="alert">
                                    As senhas não são iguais!
                         </div> </>
                                :
                                <></>
                            }
                            {
                                (enviando) ?
                                <button type="button" className="btn btn-primary mt-4"><div className="spinner-border spinner-border-sm text-light" role="status">
                                                        
                                </div></button>
                                    :
                                    <>
                                        <div className="mt-4 text-center">
                                            <button type="button" className="btgeral btn btn-primary" onClick={trocarSenha}>Trocar Senha</button>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
                    
                    <div className="col-sm-4 d-none d-sm-block"></div>
                </div>
                
            </div>
            <ModalAviso msg={msgModal} titulo={tituloModal} show={modalShow} onHide={() => setModalShow(false)} />
        </>
    );
}


export default TrocarSenhaScreen;