import User from '../data/user';

const INITIAL_STATE ={
    user: new User('anonimo','email'),
}

function usuarioReducer(state = INITIAL_STATE,action){

    switch (action.type){
        case 'SET':
            return {
                ...state,
                user:action.user
            };
        case 'SAIR':
            return{
                ...state,
                user: action.user,
            }
        default:
            return state;
    }
}

export default usuarioReducer;