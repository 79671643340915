//export const url = 'http://hbrsoftware.com.br/Abense'
export const url = '/abense'; //para o server
//export const url = ''; //local
//export const url = 'https://abense.hbrsoftware.com.br/abense';
//export const urlImgs = 'https://hbrsoftware.com.br/abense'; //local
export const urlImgs = 'https://abense.hbrsoftware.com.br/abense'; //para o server
//export const urlImgs = '';
//export const urlImgs = '.';
//export const serverPath = 'https://abensebr.com.br';

export function toData(epoca){
    var data = new Date(0);
    data.setUTCSeconds(epoca);
    return (data.toLocaleDateString() + " às " + data.toLocaleTimeString());
}

export function toDataShort(epoca){
    var data = new Date(0);
    data.setUTCSeconds(epoca);
    return data.toLocaleDateString();
}

export function toDataYYYYYMMDD(epoca){
    var data = new Date(0);

    data.setUTCSeconds(epoca);

    var retorno = (data.getFullYear()+'-'+Intl.NumberFormat('en-IN',{minimumIntegerDigits:2}).format( data.getMonth()+1)+'-'+Intl.NumberFormat('en-IN',{minimumIntegerDigits:2}).format( data.getDate()));
    return retorno;
}
export const PAGSEGURO_API = {
    path: 'https://stc.pagseguro.uol.com.br',
    sandbox: 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js',
    production : 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js',
}