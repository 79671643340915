import { url} from '../Utils.js'

export default class Noticia{

    fotoBase64 = '';
    idUser = 0;
    descricao = '';
    titulo = '';
    resumo = '';
    links = '';
    idTipo = 0;
    foto = '';
    tipoNome = '';
    idNoticia = 0;

    constructor(){

    }

    async cadNoticia(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                descricao: this.descricao,
                titulo: this.titulo,
                resumo: this.resumo,
                links: this.links,
                idTipo: this.idTipo,
                foto: this.foto,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/Noticia/cadNoticia.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;

    }

    async editarNoticia(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                idNoticia: this.idNoticia,
                descricao: this.descricao,
                titulo: this.titulo,
                resumo: this.resumo,
                links: this.links,
                idTipo: this.idTipo,
                foto: this.foto,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/Noticia/editarNoticia.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;

    }

    async deleteNoticia(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                idNoticia: this.idNoticia,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/Noticia/deleteNoticia.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;

    }

    async enviarFoto(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                foto: this.fotoBase64,
                idUser:idUser,
                jwt:jwt
            })
        };
        const response = await fetch(('/Noticia/saveFoto.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;
    }

    async getAll(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: ''
            })
        };
        const response = await fetch(url.concat('/Noticia/getAll.php'),requestOptions);
        const jsonresp = await response.json();
        let retorno = [];
        for (var i = 0; i < jsonresp.length; i++){
            var noticia = new Noticia();
            noticia.idNoticia = jsonresp[i].id;
            noticia.titulo = jsonresp[i].titulo;
            noticia.resumo = jsonresp[i].resumo;
            noticia.descricao = jsonresp[i].descricao;
            noticia.links = jsonresp[i].links;
            //noticia.idUser = jsonresp[i].idUser;
            noticia.idTipo = jsonresp[i].idTipo;
            noticia.foto = jsonresp[i].foto;
            noticia.tipoNome = jsonresp[i].tipoNome;
            retorno.push(noticia);
        }   
        return retorno;
    }

    async get5(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: ''
            })
        };
        const response = await fetch(url.concat('/Noticia/get5.php'),requestOptions);
        const jsonresp = await response.json();
        let retorno = [];
        for (var i = 0; i < jsonresp.length; i++){
            var noticia = new Noticia();
            noticia.idNoticia = jsonresp[i].id;
            noticia.titulo = jsonresp[i].titulo;
            noticia.resumo = jsonresp[i].resumo;
            noticia.descricao = jsonresp[i].descricao;
            noticia.links = jsonresp[i].links;
            //noticia.idUser = jsonresp[i].idUser;
            noticia.idTipo = jsonresp[i].idTipo;
            noticia.foto = jsonresp[i].foto;
            noticia.tipoNome = jsonresp[i].tipoNome;
            retorno.push(noticia);
        }   
        return retorno;
    }

    async getNoticiaById(idNoticia) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idNoticia: idNoticia
            })
        };
        const response = await fetch(url+'/Noticia/getNoticiaById.php', requestOptions);
        const jsonresp = await response.json();

        var noticia = new Noticia();
        noticia.id = jsonresp.id;
        noticia.titulo = jsonresp.titulo;
        noticia.resumo = jsonresp.resumo;
        noticia.descricao = jsonresp.descricao;
        noticia.links = jsonresp.links;
        //noticia.idUser = jsonresp.idUser;
        noticia.idTipo = jsonresp.idTipo;
        noticia.foto = jsonresp.foto;
        noticia.tipoNome = jsonresp.tipoNome;


        return noticia;
    }

}