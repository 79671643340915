import {url} from '../Utils.js';

const TIPOS = {
    ADMIN: 1,
    PARCEIRO: 2,
    CLIENTE: 3,
    JURIDICO: 5,
    COMERCIAL: 4
}

export default class TipoUser{
    id = 0;
    descricao = '';

    static get Tipos(){
        return TIPOS;
    }
    constructor(id,descricao){
        this.id = id;
        this.descricao = descricao;
    }

    async getAllTipos(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ tipo: this.tipo })
        };

        const response = await fetch(url.concat('/utils/getAllTipos.php'),requestOptions);
        const jsonresp = await response.json();
        let retorno = [];
        for (var i = 0; i < jsonresp.length; i++){
            var tipo = new TipoUser(jsonresp[i].id,jsonresp[i].descricao);
            retorno.push(tipo);
        }
        return retorno;
    }

    async getTiposAberto(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ tipo: this.tipo })
        };

        const response = await fetch(url.concat('/utils/getTiposAberto.php'),requestOptions);
        const jsonresp = await response.json();
        let retorno = [];
        for (var i = 0; i < jsonresp.length; i++){
            var tipo = new TipoUser(jsonresp[i].id,jsonresp[i].descricao);
            retorno.push(tipo);
        }
        return retorno;
    }
}