import { url } from "../Utils";

export default class Curso{
    fotoBase64 = '';
    idUser = 0;
    descricao = '';
    titulo = '';
    ch = 0;
    datai = 0;
    dataf = 0;
    horai = "";
    horaf = "";
    foto = '';
    tipo = -1;
    idCurso = -1;
    inscrito = 0;
    vagas = 0;

    constructor(){

    }

    async cadCurso(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                descricao: this.descricao,
                titulo: this.titulo,
                ch: this.ch,
                datai: this.datai,
                horai: this.horai,
                horaf: this.horaf,
                dataf: this.dataf,
                foto: this.foto,
                tipo:this.tipo,
                vagas:this.vagas,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/curso/cadCurso.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;

    }

    async editarCurso(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                idCurso:this.idCurso,
                descricao: this.descricao,
                titulo: this.titulo,
                ch: this.ch,
                datai: this.datai,
                horai: this.horai,
                horaf: this.horaf,
                dataf: this.dataf,
                foto: this.foto,
                tipo:this.tipo,
                vagas:this.vagas,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/curso/editarCurso.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;

    }
    async inscreverCurso(idUser,jwt,idCurso,idUserCurso){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                idCurso: idCurso,
                idUserCurso: idUserCurso,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/curso/inscreverCurso.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;

    }
    async desinscreverCurso(idUser,jwt,idCurso,idUserCurso){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                idCurso: idCurso,
                idUserCurso: idUserCurso,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/curso/desinscreverCurso.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;

    }
    async deleteCurso(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                idCurso:this.idCurso,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/curso/deleteCurso.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;

    }

    async enviarFoto(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                foto: this.fotoBase64,
                idUser:idUser,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/curso/saveFoto.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;
    }
    
    async getAll(idUser = 0){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser
            })
        };
        const response = await fetch(url.concat('/curso/getAll.php'),requestOptions);
        const jsonresp = await response.json();
        let retorno = [];
        for (var i = 0; i < jsonresp.length; i++){
            var curso = new Curso();
            curso.idCurso = jsonresp[i].id;
            curso.titulo = jsonresp[i].titulo;
            curso.descricao = jsonresp[i].descricao;
            curso.foto = jsonresp[i].foto;
            curso.datai = jsonresp[i].datai;
            curso.dataf = jsonresp[i].dataf;
            curso.horai = jsonresp[i].horai;
            curso.horaf = jsonresp[i].horaf;
            curso.ch = jsonresp[i].ch;
            curso.inscrito = jsonresp[i].inscrito;
            curso.inscritos = jsonresp[i].inscritos;
            curso.vagas = jsonresp[i].vagas;
            retorno.push(curso);
        }   
        return retorno;
    }

    async getlistaInscritosCursoById(idCurso,idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idCurso: idCurso,
                idUser:idUser,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/curso/listaInscritosCursoById.php'),requestOptions);
        const jsonresp = await response.json();
        
        let linhas = [];
        const lista = jsonresp.lista;
        const retorno = {
            titulo: jsonresp.titulo,
            vagas: jsonresp.vagas,
            inscritos: jsonresp.inscritos,
            lista:[]
        }
        for (var i = 0; i < lista.length; i++){
            let linhaEscrito ={
                nome: lista[i].nome,
                telefone: lista[i].telefone,
                
            }
            
            linhas.push(linhaEscrito);
        }   
        retorno.lista = linhas;
        console.log(retorno);
        return retorno;
    }

    async get5(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: ''
            })
        };
        const response = await fetch(url.concat('/curso/get5.php'),requestOptions);
        const jsonresp = await response.json();
        let retorno = [];
        for (var i = 0; i < jsonresp.length; i++){
            var curso = new Curso();
            curso.idCurso = jsonresp[i].id;
            curso.titulo = jsonresp[i].titulo;
            curso.descricao = jsonresp[i].descricao;
            curso.foto = jsonresp[i].foto;
            curso.datai = jsonresp[i].datai;
            curso.dataf = jsonresp[i].dataf;
            curso.horai = jsonresp[i].horai;
            curso.horaf = jsonresp[i].horaf;
            curso.ch = jsonresp[i].ch;
            retorno.push(curso);
        }   
        return retorno;
    }

    async getCursoById(idCurso) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idCurso: idCurso
            })
        };
        const response = await fetch(url.concat('/curso/getCursoById.php'), requestOptions);
        const jsonresp = await response.json();


        var curso = new Curso();
        curso.id = jsonresp.id;
        curso.titulo = jsonresp.titulo;
        curso.descricao = jsonresp.descricao;
        curso.foto = jsonresp.foto;
        curso.datai = jsonresp.datai;
        curso.dataf = jsonresp.dataf;
        curso.horai = jsonresp.horai;
        curso.horaf = jsonresp.horaf;
        curso.ch = jsonresp.ch;
        curso.vagas = jsonresp.vagas;


        return curso;
    }
}