import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Footer from '../../components/footer';
import NavBar from '../../components/navbar';

import inMemoryJwt from '../../data/inMemoryJwt';
import User from '../../data/user';
import { store } from '../../store/userStore';
import CardAssociado from './components/cardassociado';



function ListaAssociadosScreen() {
    const [statusPagina, setStatusPagina] = useState(0);
    const [listaAssociados, setListaAssociados] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [logado,setLogado] = useState(0);

    
    var state = store.getState();
    const logado2 = useSelector(state => state.user.hora);
    let { id } = useParams();
    useEffect(() => {
        setStatusPagina(1);
        var associado = new User();
        associado.getClientes(state.user.id2, inMemoryJwt.getToken()).then((lista) => {
            setListaAssociados(lista);
        });
        setStatusPagina(0);
    }, [logado2]);

    useEffect(()=>{
        
        if (inMemoryJwt.getToken()){
            setLogado(1);
            
        } else{
            setLogado(0);
        }
        
    },[logado2]);
    return (
        <div className='row'>
            <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
            <div className="col-sm-10 p-0">
                <NavBar />
                <div className="row justify-content-center p-4">
                    <div className="container mt-4 align-items-center">
                        {
                            logado === 0 ?
                            <div>
                                <h3>Caro usuário você precisa refazer seu login para acessar esta lista.</h3>
                            </div>
                            :
                            statusPagina === 0 ?
                                <>
                                    <div className='container'>
                                        <h3>Lista de Associados</h3>
                                        <h5>{"Total de Associados: " + listaAssociados.length}</h5>
                                        <CardAssociado
                                            nome="Nome"
                                            telefone="Telefone"
                                            email="e-mail"
                                            index={-3}
                                        />
                                        {
                                            listaAssociados.map((inscrito,index) => <CardAssociado
                                                nome={inscrito.nome}
                                                telefone={inscrito.telefone}
                                                email={inscrito.email}
                                                index={index}
                                            />)
                                        }
                                    </div>
                                </>
                                :
                                <>

                                </>
                        }
                    </div>
                    <Footer/>
                </div>
            </div>
            <div className="col-sm-1" id="ladodireito"></div>
        </div>
    );
}

export default ListaAssociadosScreen;