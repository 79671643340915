import React, { useState, useEffect } from 'react';
import './navbar.css';
import logo from '../../image/logo2.png';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Navigate, Redirect } from 'react-router-dom';
import LoginScreen from '../../view/login';
import User from '../../data/user';
import { store } from '../../store/userStore.js';
import { useNavigate } from "react-router-dom";
import logged_iconM from '../../image/icon/loggedM.png';
import logged_iconF from '../../image/icon/loggedF.png';
import inMemoryJwt from '../../data/inMemoryJwt.js';
import TipoUser from '../../data/tipouser';
import { Modal } from 'react-bootstrap';
import logo2 from "../../image/logo2.png"
import sergipe from "../../image/Sergipe.png"
function Navbar() {
    const [loginModal, setLoginModal] = useState(false);
    const [tipoUser, setTipoUser] = useState(-1);
    const [jwtExpirado,setJwtExpirado] = useState(false);
    var state = store.getState();
    const [logged, setLogged] = useState(state.user.logged);
    let jwtValido;

    const refreshToken = (delay) => {
        jwtValido = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const getRefreshedToken = () => {
        if (inMemoryJwt.getToken()){
            setJwtExpirado(false);
            refreshToken(30);
        } else{
            setJwtExpirado(true);
        }
    }

    let navigate = useNavigate();

    function handleAbrirLogin() {
        setLoginModal(true);
    }
    const handleClose = ()=>{
        setLoginModal(false);
        state = store.getState();
        setLogged(state.user.logged);
    }
    
    const dispatch = useDispatch();
    function logout() {
        inMemoryJwt.setTipo_user(-1);
        inMemoryJwt.ereaseToken();
        dispatch({
            type: "SAIR",
            user: new User('anonimo', 'email', 0)
        });
        state = store.getState();
        setLogged(state.user.logged);
        navigate("/");
        
    }
    useEffect(()=>{
        inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        refreshToken(5);
    },[inMemoryJwt.getToken(),logged]);

    return (
        <>
            <div className="row">
            <div className="col-12">
            { jwtExpirado ?

            <div className="alert alert-primary alert-dismissible mt-2 text-center" role="alert">
                   Caro Usuário seu login expirou, <strong onClick={handleAbrirLogin} className="linkloginaviso">clique aqui</strong> para refazer seu login.
                   <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>           
                </div>
            :
            <></>
            }
            </div>
            </div>
            <div className="row">
            
                <div className="col-12">
                <div className="row justify-content-center align-items-center">
                            <div className="col-sm-2 d-none d-sm-block">
                                <img src={logo2} className="image-fluid" id="logo"></img>

                            </div>
                            <div className="col-8">
                                <div className="d-flex flex-row bd-highlight mb-3 justify-content-center">
                                    <h1 className="text-end" id="ABEn-id">ABEn</h1>
                                    <h1 className="text-start" id="Ser-id">Ser</h1>
                                    <h1 className="text-center" id="gipe-id">gipe</h1>
                                </div>

                            </div>
                            <div className="col-sm-2 d-none d-sm-block"><img src={sergipe} className="image-fluid" id="logo"></img></div>
                        </div>
                    <nav className='navbar sticky-top navbar-expand-lg'>
                        
                        

                        <div className="navbar-collapse collapse w-100 order-1 dual-collapse2" id="navbarNavDropdown">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item ms-3">
                                    <Link className="nav-link" to="/">Início</Link>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <div className="nav-link" href="#" id="navbarDropdownMenuLink1" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Institucional</div>
                                    <div className="drop-menu dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
                                        <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/caduser">Missão e Valores</Link></button>
                                        <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/listausers">Diretoria</Link></button>


                                    </div>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/quemsomos">Quem Somos</Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/listacursos">Cursos e Eventos</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/listanoticias">Notícias</Link>
                                </li>
                                {
                                    tipoUser === TipoUser.Tipos.ADMIN ?
                                        <>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Administração</div>
                                                <div className="drop-menu dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/listaassociados">Lista de associados</Link></button>
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/cadnoticia">Cadastrar Notícia</Link></button>
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/cadcurso">Cadastrar Curso</Link></button>
                                                    

                                                </div>
                                            </li>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    tipoUser === TipoUser.Tipos.COMERCIAL ?
                                        <>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Comercial</div>
                                                <div className="drop-menu dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/cadnoticia">Cadastrar Notícia</Link></button>
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/cadparceiro">Cadastrar Parceiro</Link></button>
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/listaproposta">Solicitações de novas Parcerias</Link></button>
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/chatadmin">Chats de Atendimento</Link></button>

                                                </div>
                                            </li>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    tipoUser === TipoUser.Tipos.JURIDICO ?
                                        <>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Jurídico</div>
                                                <div className="drop-menu dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/cadprocesso">Cadastrar Processo</Link></button>
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/listaprocessoadmin">Cadastrar Movimentação de Processo</Link></button>

                                                </div>
                                            </li>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    tipoUser === TipoUser.Tipos.PARCEIRO ?
                                        <>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Área do Parceiro</div>
                                                <div className="drop-menu dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <button className="button-nav btn" href="#"><Link className="nav-bt-menu" to="/verificarusuario">Verificar Usuário</Link></button>

                                                </div>
                                            </li>
                                        </>
                                        :
                                        <></>
                                }
                            </ul>
                        </div>
                        <div className="bt-togle mx-auto order-2">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".dual-collapse2">
                                <i className="ico-togle fas fa-bars"></i>
                            </button>
                        </div>
                        <div className="w-50 my-2 order-3">
                            {
                                !logged ?
                                    <ul className="navbar-nav ml-auto">
                                        <li className="login-bt nav-item">
                                            <div className="my-lg-0">
                                                <button type="button" className="btn bt-roxo" onClick={handleAbrirLogin}>Associe-se
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                    :
                                    <ul>
                                    <li className="nav-item dropdown">
                                            
                                                <a  className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><img className="logged-icon d-inline" src={ state.user.sexo == 0 ? logged_iconM : logged_iconF} /><span className="d-inline">{state.user.nome.split(" ")[0]}</span>
                                                </a>
                                            
                                            <ul className="drop-menu dropdown-menu" aria-labelledby="navbarDropdown">
                                                
                                                
                                                <li><div className="text-center"><button className="button-nav btn" ><Link className="nav-link-drop" to="/trocasenha">Trocar Senha</Link></button></div></li>
                                                
                                                <li><div className="text-center"><button className="button-nav btn" type="button" onClick={logout}>Sair</button></div></li>
                                            </ul>
                                        
                                    </li>
                                    </ul>
                            }
                        </div>
                    </nav>
                </div>
            </div>

             <Modal
                show={loginModal}
                onHide={handleClose}
                size={"sm"}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titulo-modal mx-auto"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginScreen fecharModal={handleClose} />
                </Modal.Body>
                
            </Modal>
        </>
    )
}

export default Navbar;