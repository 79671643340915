import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import  './quemsomos.css';

function QuemSomosScreen(){

    return(
        
            <div className="row">
                <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
                <div className="col-10 p-0">
                    <div className='card'>
                        <Navbar />

                        <div className="row">
                            <div className="col-sm-3  d-none d-sm-block"></div>
                            <div className="col-sm-6 mt-4" align="center" id="quem-somos-texto">
                                <h2>Quem Somos</h2>
                                <p>A natureza e a finalidade da ABEn conferem-lhe caráter cultural, científico
                                    e político. Pautada em princípios éticos e legais e, em conformidade com suas
                                    competências, a ABEn articula-se a organizações nacionais e internacionais para
                                    desenvolvimento profissional, científico, social, político e cultural da
                                    Enfermagem brasileira.
                                </p>
                            </div>
                            <div className="col-sm-3  d-none d-sm-block"></div>
                        </div>
                        <Footer/>
                    </div>
                </div>
                <div className="col-sm-1" id="ladodireito"></div>
            </div>

       
    );

}
export default QuemSomosScreen;