import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Noticia from "../../data/noticia";
import { urlImgs } from "../../Utils";
import "./carroselnoticias2.css";

function CarrosselNoticias2(props) {


    const [noticias,setNoticias] = useState([]);
    let navigate = useNavigate();
    useEffect(()=>{
        var noticias_aux = new Noticia();
        noticias_aux.get5().then((retorno)=>{
            setNoticias(retorno);
        });
    },[]);

    const linkpara = (link)=>{
        navigate('/noticiadetalhe/'+link);
    }

    return (
        <>
            
            <div id="carouselExampleSlidesOnly" className="carousel slide mw-50" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {
                    noticias.map((noticia,index)=>{
                        if (index ==0){
                        return (
                            <button type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" key={"btcarrnot"+index}></button>

                        )
                        }else{
                            return (
                                <button type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to={index} aria-label={"Slide "+(index+1)} key={"btcarrnot"+index}></button>
                            )
                        }
                    })
                }
                </div>

                
                <div className="carousel-inner">
                    {
                        noticias.map((noticia, index) => {
                            let classname = index == 0 ? "carousel-item active" : "carousel-item";
                            return (
                                <div className={classname} key={"crrsoel" + index} onClick={()=>linkpara(noticia.idNoticia)}>
                                    
                                    <div className="row p-5">
                                        <div className="col-md-6 card p-0" id="col-img">
                                            <img src={urlImgs + "/Noticia/img/" + noticia.foto}  id="img-titulo" className="" alt="..." />
                                        </div>
                                        <div className="col-md-6  card" id="col-noticia-texto">
                                        <h1 className="text-center" id="noticia-titulo">{noticia.titulo}</h1>
                                            <p id="txt-resumonoticia" className="ms-2">{noticia.resumo}</p>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                
                                </div>
                            )
                        })
                    }
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            
        </>
    );

}
export default CarrosselNoticias2;