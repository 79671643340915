import './cadUser.css';

import React, { useState } from 'react';
import NavBar from '../../components/navbar';
import DropProfissoes from './components/dropprofissoes';

import User from '../../data/user';
import { Navigate, Redirect } from 'react-router-dom';

import ImageUploader from 'react-images-upload';
import DropTipoUser from './components/droptipouser.js';
import { useSelector, useDispatch } from 'react-redux';
import CpfCnpjInput from 'react-cpf-cnpj-input';
import ModalAviso from '../../components/avisos';


import inMemoryJwt from '../../data/inMemoryJwt';
import { store } from '../../store/userStore';
import TipoUser from '../../data/tipouser';
import { url } from '../../Utils';
import Footer from '../../components/footer';


function CadUserScreen() {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const [datanascimento, setDataNascimento] = useState();
    const [sexo, setSexo] = useState(0);
    const [profissao, setProfissao] = useState(-1);
    const [escolaridade, setEscolaridade] = useState(1);
    const [senha, setSenha] = useState('');
    const [senha2, setSenha2] = useState('');
    const [senhasDiferentes, setSenhaDiferente] = useState();
    const [emailsDiferentes, setEmailsDiferentes] = useState();
    const [erroNome, setErroNome] = useState('');
    const [statusPagina, setStatusPagina] = useState(0);
    const [statusCadastrar, setStatusCadastrar] = useState(0);
    const [redirecionar, setRedirecionar] = useState(false);
    const [cpfcnpj, setCpfCnpj] = useState('');
    const [cepResidencial, SetCepResidencial] = useState('');
    const [enderecoResidencial, SetEnderecoResidencial] = useState('');
    const [complementoResidencial, SetComplementoResidencial] = useState('');
    const [bairroResidencial, SetBairroResidencial] = useState('');
    const [ufResidencial, SetUfResidencial] = useState('');
    const [cidadeResidencial, SetCidadeResidencial] = useState('');
    const [telefone, SetTelefone] = useState('');

    const [imagem, setImagem] = useState();
    const [imgPath, setImgPath] = useState();
    const [tipouser, setTipoUser] = useState(-1);


    const [msgModal, setMsgModal] = useState('');
    const [tituloModal, setTituloModal] = useState('');
    const [modalShow, setModalShow] = useState(false);

    var state = store.getState();

    async function onDrop(picture, pictureURL) {
        setImagem(picture);
        setImgPath(pictureURL);
        //loadimage();
    }
    var cpfcnpjS = '';
    const erro = {
        nome: '',
        cpfcnpj: '',
        email: '',
        email2: '',
        datanascimento: '',
        sexo: '',
        profissao: '',
        escolaridade: '',
        senha: '',
        senha2: '',
        tipouser: '',
        foto: '',
        telefone: ''
    }
    const [erros, setErros] = useState(erro);

    async function enviarFoto() {
        var usuario = new User();
        usuario.fotoBase64 = imgPath[0];
        return await usuario.enviarFoto();
    }
    async function cadastrar() {


        //setStatusCadastrar(1);
        const data = Date.parse(datanascimento).valueOf() / 1000;
        let user = new User();
        if (cpfcnpj == null)
            cpfcnpjS = '';
        else
            cpfcnpjS = cpfcnpj;
        cpfcnpjS = cpfcnpjS.trim();
        cpfcnpjS = cpfcnpjS.replaceAll('.', '');
        cpfcnpjS = cpfcnpjS.replaceAll('/', '');
        cpfcnpjS = cpfcnpjS.replaceAll('_', '');
        cpfcnpjS = cpfcnpjS.replaceAll('-', '');
        user.nome = nome;
        user.email = email;
        user.tipo = tipouser;
        user.sexo = sexo;
        user.datanascimento = data;
        user.profissao = profissao;
        user.escolaridade = escolaridade;
        user.senha = senha;
        user.cpfcnpj = cpfcnpjS;
        user.cep = cepResidencial;
        user.endereco = enderecoResidencial;
        user.complemento = complementoResidencial;
        user.bairro = bairroResidencial;
        user.uf = ufResidencial;
        user.cidade = cidadeResidencial;
        user.telefone = telefone;
        if (validar()) {
            if (imgPath == null) {
                user.foto = 'logo.png';
            } else {
                const fotourl = await enviarFoto();
                user.foto = fotourl;
            }
            user.cadUser(state.user.id2, inMemoryJwt.getToken()).then((resposta) => {
                if (resposta.success > 0) {
                    setTituloModal('Bem vindo a ABEn-SE!');
                    setMsgModal('Cadastro efetuado com sucesso.');
                    setModalShow(true);
                    limpar();
                } else {
                    setTituloModal('Falha no cadastro');
                    setMsgModal(resposta.error);
                    setModalShow(true);
                }
            }
            );
        }
        setStatusCadastrar(0);
    }
    function limpar() {
        setNome('');
        setCpfCnpj('');
        setEmail('');
        setEmail2('');
        setSenha('');
        setSenha2('');
        setDataNascimento('1985-01-01');
        SetTelefone('');
    }
    function validar() {
        erro.nome = '';
        erro.cpfcnpj = '';
        erro.email = '';
        erro.email2 = '';
        erro.datanascimento = '';
        erro.sexo = '';
        erro.profissao = '';
        erro.escolaridade = '';
        erro.senha = '';
        erro.senha2 = '';
        erro.foto = '';
        erro.tipouser = '';
        erro.telefone = '';
        erro.cep = '';
        erro.endereco = '';
        erro.bairro = '';
        erro.uf = '';
        erro.cidade = '';
        var valido = true;

        if (senha == null || senha == '') {
            erro.senha = 'Preencha a senha';
            valido = false;
        } else {
            if (senha !== senha2) {
                erro.senha = 'Senhas são diferentes';
                valido = false;
            }
        }
        if (email == null || email == '') {
            erro.email = 'Preencha o e-mail';
            valido = false;
        } else {
            if (email !== email2) {
                erro.email = 'Os e-mails são diferentes';
                valido = false;
            }
        }
        if (nome == null || nome == '') {
            erro.nome = 'Preencha o nome completo';
            valido = false;
        }
        if (telefone == null || telefone == '') {
            erro.telefone = 'Preencha o telefone';
            valido = false;
        }
        if (datanascimento == null) {
            erro.datanascimento = 'Escolha uma data de nascimento';
            valido = false;
        }
        if (profissao == null || profissao == -1) {
            erro.profissao = 'Escolha uma profissão';
            valido = false;
        }

        if (sexo == null) {
            erro.sexo = 'Escolha seu gênero';
            valido = false;
        }
        if (cepResidencial == null || cepResidencial == '') {
            erro.cep = 'Digite seu CEP.';
            valido = false;
        }
        if (enderecoResidencial == null || enderecoResidencial == '') {
            erro.endereco = 'Digite seu endereço.';
            valido = false;
        }
        if (bairroResidencial == null || bairroResidencial == '') {
            erro.bairro = 'Digite seu Bairro.';
            valido = false;
        }
        if (ufResidencial == null || ufResidencial == '') {
            erro.uf = 'Digite seu Estado.';
            valido = false;
        }
        if (cidadeResidencial == null || cidadeResidencial == '') {
            erro.cidade = 'Digite sua Cidade.';
            valido = false;
        }

        let cpf = require('react-cpf-cnpj-input');
        /*  if (!cpf.validate(cpfcnpjS)) {
             erro.cpfcnpj = 'CPF inválido!';
             valido = false;
         } */
        if (cpfcnpj == null || cpfcnpj == '') {

            erro.cpfcnpj = 'Você deve digitar um CPF ou um CNPJ válido';
            valido = false;
        }
        if (tipouser === -1) {

            erro.tipouser = 'Você deve escolher o tipo de usuário';
            valido = false;
        }
        //if (imgPath == null) {
        //   erro.foto = 'Você deve selecionar uma foto';
        //   valido = false;
        //}

        setErros(erro);
        console.log(erro);
        return valido;
    }
    function compararSenha(senhatemp) {
        var senha2 = '';
        senha2 = senha;
        var senhatemp2 = '';
        senhatemp2 = senhatemp;
        if (senha2 == null) {
            return;
        }
        if (senhatemp2 !== senha2.substring(0, senhatemp2.length)) {
            setSenhaDiferente(1);
        }
        else {
            setSenhaDiferente(0);
        }
        setSenha2(senhatemp);
    }
    function compararEmail(emailtemp) {
        if (email == null) {
            return;
        }
        var email2 = '';
        email2 = email;
        var emailtemp2 = '';
        emailtemp2 = emailtemp;
        if (emailtemp2 !== email2.substring(0, emailtemp2.length)) {
            setEmailsDiferentes(1);
        }
        else {
            setEmailsDiferentes(0);
        }
        setEmail2(emailtemp);
    }
    function handleSelectProfissao(profissao) {
        setProfissao(profissao);

    }
    function handleSelectEscolaridade(escolaridade) {
        setEscolaridade(escolaridade);

    }
    function handleSexoChange(changeEvent) {
        setSexo(changeEvent.target.value);

    }

    function handleSelectTipoUser(tipo) {
        setTipoUser(tipo);
    }
    function getCEPResidencial(cep) {
        const re = /^[0-9\b]+$/;
        if (cep === '' || re.test(cep)) {
            SetCepResidencial(cep)
        }
        if (cep.length > 7) {
            getCEP(cep).then((data) => {
                SetEnderecoResidencial(data.logradouro);
                SetBairroResidencial(data.bairro);
                SetCidadeResidencial(data.localidade);
                SetUfResidencial(data.uf);
            });
        }
    }
    async function getCEP(cep) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                cep: cep
            })
        };
        const response = await fetch(url.concat('/utils/getCEP.php'), requestOptions);
        const jsonresp = await response.json();
        return jsonresp;
    }
    if (redirecionar) {
        return <Navigate to='/' />;
    } else {
        return (
            <>
                <div className="row">
                    <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
                    <div className="col-10 p-0">
                        <div className='card'>
                            <NavBar />
                            <div className="row justify-content-center">



                                <div className="col-sm-6">
                                    <div className="container mt-4 align-items-center">
                                        {
                                            statusPagina === 0 ?
                                                <>

                                                    <div className="Container">
                                                        <form className="Form">
                                                            <div className="row">
                                                                <div className="col-sm-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="nomeinput" className="txt-roxo">Seu nome completo</label>
                                                                        <input type="text" className="form-control required" id="nomeinput" onChange={(e) => setNome(e.target.value)} value={nome}></input>
                                                                        {erros.nome !== '' ?
                                                                            <> <div className="alert alert-danger" role="alert">
                                                                                {erros.nome}
                                                                            </div> </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="cpfcnpj" className="txt-roxo">CPF</label>
                                                                        <CpfCnpjInput value={cpfcnpj} onChange={(e) => setCpfCnpj(e.target.value)} className="form-control required" id="cpfinput" />
                                                                        {erros.cpfcnpj !== '' ?
                                                                            <> <div className="alert alert-danger" role="alert">
                                                                                {erros.cpfcnpj}
                                                                            </div> </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="emailinput" className="txt-roxo">Endereço de e-mail</label>
                                                                        <input type="email" className="form-control" id="emailinput" onChange={(e) => setEmail(e.target.value)} value={email}></input>
                                                                        {erros.email !== '' ?
                                                                            <> <div className="alert alert-danger" role="alert">
                                                                                {erros.email}
                                                                            </div> </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="emailinput2" className="txt-roxo">Repita o Endereço de e-mail</label>
                                                                        <input type="email" className="form-control" id="emailinput2" onChange={(e) => compararEmail(e.target.value)} value={email2}></input>
                                                                        {(emailsDiferentes === 1) ?
                                                                            <> <div className="alert alert-danger" role="alert">
                                                                                Os e-mails não são iguais!
                                                                            </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="senhainput" className="txt-roxo">Senha</label>
                                                                        <input type="password" className="form-control" id="senhainput" onChange={(e) => setSenha(e.target.value)} value={senha}></input>
                                                                        {erros.senha !== '' ?
                                                                            <> <div className="alert alert-danger" role="alert">
                                                                                {erros.senha}
                                                                            </div> </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="senhainput2" className="txt-roxo">Repita a senha</label>
                                                                        <input type="password" className="form-control" id="senhainput2" onChange={(e) => compararSenha(e.target.value)} value={senha2}></input>

                                                                        {(senhasDiferentes === 1) ?
                                                                            <> <div className="alert alert-danger" role="alert">
                                                                                As senhas não são iguais!
                                                                            </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-5">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dantanascimento" className="txt-roxo">Data de Nascimento</label>
                                                                        <input type="date" id="datanascimento" className="form-control" onChange={(e) => setDataNascimento(e.target.value)} value={datanascimento}></input>
                                                                        {erros.datanascimento !== '' ?
                                                                            <> <div className="alert alert-danger" role="alert">
                                                                                {erros.datanascimento}
                                                                            </div> </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <fieldset className="form-group">
                                                                        <legend className="txt-roxo col-form-label col-sm-2 pt-0">Sexo</legend>
                                                                        <div className="row">
                                                                            <div className="col-sm-10">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input onChange={handleSexoChange} className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value='0' />
                                                                                    <label className="txt-roxo form-check-label" htmlFor="gridRadios1">
                                                                                        Feminino
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input onChange={handleSexoChange} className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="1" />
                                                                                    <label className="txt-roxo form-check-label" htmlFor="gridRadios2">
                                                                                        Masculino
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input onChange={handleSexoChange} className="form-check-input" type="radio" name="gridRadios" id="gridRadios3" value="2" />
                                                                                    <label className="txt-roxo form-check-label" htmlFor="gridRadios3">
                                                                                        Outro
                                                                                    </label>
                                                                                </div>
                                                                                {erros.sexo !== '' ?
                                                                                    <> <div className="alert alert-danger" role="alert">
                                                                                        {erros.sexo}
                                                                                    </div> </>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </fieldset>
                                                                </div>

                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">

                                                                        <DropProfissoes value={profissao} setValue={handleSelectProfissao} label={"Categoria Profissional"} erro={erros.profissao} />

                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-4'>
                                                                    <div className="form-group">
                                                                        <label htmlFor="idtelefone" className="txt-roxo">Telefone</label>
                                                                        <input type="tel" id="idtelefone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="form-control" onChange={(e) => SetTelefone(e.target.value)} value={telefone}></input>
                                                                        {erros.telefone !== '' ?
                                                                            <> <div className="alert alert-danger" role="alert">
                                                                                {erros.telefone}
                                                                            </div> </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label className="txt-roxo">Tipo de Usuário</label>
                                                                        {
                                                                            inMemoryJwt.getTipo_user() === TipoUser.Tipos.ADMIN ?
                                                                                <>
                                                                                    <DropTipoUser selectedTipoUser={handleSelectTipoUser} userAdmin={true} />
                                                                                    {erros.tipouser !== '' ?
                                                                                        <> <div className="alert alert-danger" role="alert">
                                                                                            {erros.tipouser}
                                                                                        </div> </>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <DropTipoUser selectedTipoUser={handleSelectTipoUser} userAdmin={false} />
                                                                                    {erros.tipouser !== '' ?
                                                                                        <> <div className="alert alert-danger" role="alert">
                                                                                            {erros.tipouser}
                                                                                        </div> </>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </>
                                                                        }

                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="">
                                                                <h4>Endereço Residencial</h4>
                                                                <div className="row">
                                                                    <div className="col-sm-2">
                                                                        <div className="form-group">
                                                                            <label htmlFor="ceprinput" className="txt-roxo">CEP</label>
                                                                            <input type="text" className="form-control required" id="ceprinput" value={cepResidencial} onChange={(e) => getCEPResidencial(e.target.value)}></input>
                                                                            {erros.cep !== '' ?
                                                                                <> <div className="alert alert-danger" role="alert">
                                                                                    {erros.cep}
                                                                                </div> </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="erinput" className="txt-roxo">Endereço</label>
                                                                            <input type="text" className="form-control required" value={enderecoResidencial} onChange={(e) => SetEnderecoResidencial(e.target.value)} className="form-control required" id="erinput" />
                                                                            {erros.endereco !== '' ?
                                                                                <> <div className="alert alert-danger" role="alert">
                                                                                    {erros.endereco}
                                                                                </div> </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="complemento12" className="txt-roxo">Complemento</label>
                                                                            <input type="text" className="form-control required" value={complementoResidencial} onChange={(e) => SetComplementoResidencial(e.target.value)} className="form-control required" id="complementoinput2" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-5">
                                                                        <div className="form-group">
                                                                            <label htmlFor="bairrorinput" className="txt-roxo">Bairro</label>
                                                                            <input type="text" className="form-control required" value={bairroResidencial} onChange={(e) => SetBairroResidencial(e.target.value)} className="form-control required" id="bairrorinput" />
                                                                            {erros.bairro !== '' ?
                                                                                <> <div className="alert alert-danger" role="alert">
                                                                                    {erros.bairro}
                                                                                </div> </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <div className="form-group">
                                                                            <label htmlFor="uf5input" className="txt-roxo">UF</label>
                                                                            <input type="text" className="form-control required" value={ufResidencial} onChange={(e) => SetUfResidencial(e.target.value)} className="form-control required" id="uf5input" />
                                                                            {erros.uf !== '' ?
                                                                                <> <div className="alert alert-danger" role="alert">
                                                                                    {erros.uf}
                                                                                </div> </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-5">
                                                                        <div className="form-group">
                                                                            <label htmlFor="cidaderinput" className="txt-roxo">Cidade</label>
                                                                            <input type="text" className="form-control required" value={cidadeResidencial} onChange={(e) => SetCidadeResidencial(e.target.value)} className="form-control required" id="cidaderinput" />
                                                                            {erros.cidade !== '' ?
                                                                                <> <div className="alert alert-danger" role="alert">
                                                                                    {erros.cidade}
                                                                                </div> </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ImageUploader
                                                                withIcon={true}
                                                                buttonText='Escolha uma imagem'
                                                                onChange={onDrop}
                                                                imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                                maxFileSize={5242880}
                                                                withPreview={true}
                                                                label='Tamanho máximo: 5mb'

                                                            />
                                                            {erros.foto !== '' ?
                                                                <> <div className="alert alert-danger" role="alert">
                                                                    {erros.foto}
                                                                </div> </>
                                                                :
                                                                <></>
                                                            }

                                                            {
                                                                statusCadastrar === 1 ?
                                                                    <>
                                                                        <button type="button" className="btn btn-primary"><div className="spinner-border spinner-border-sm text-light" role="status">

                                                                        </div></button>
                                                                        <div className="flex mx-auto">
                                                                            <h6 className="txt-roxo">Cadastrando, Aguarde um segundo.</h6>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="div-bt text-center">
                                                                            <button type="button" className="btn btn-primary" onClick={cadastrar}>Cadastrar</button>
                                                                        </div>
                                                                    </>
                                                            }

                                                        </form>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="container">
                                                        <div className="col">
                                                            <div className="spinner spinner-border text-primary row" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                            <span className="row">Cadastro efetuado com Sucesso!</span>
                                                            <span className="row">Agora você será redirecionado</span>
                                                        </div>
                                                    </div>
                                                </>

                                        }
                                    </div>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </div>
                    <div className="col-sm-1" id="ladodireito"></div>
                </div>


                <ModalAviso msg={msgModal} titulo={tituloModal} show={modalShow} onHide={() => setModalShow(false)} />
            </>
        );
    }
}

export default CadUserScreen;