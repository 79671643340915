import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Noticia from "../../data/noticia";
import { urlImgs } from "../../Utils";
import './listanoticiasinical.css';
function ListaNoticiasInicial() {

    const [noticias, setNoticias] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        var noticias_aux = new Noticia();
        noticias_aux.get5().then((retorno) => {
            setNoticias(retorno);
        });
    }, []);

    const linkpara = (link) => {
        navigate('/noticiadetalhe/' + link);
    }


    return (
        <div className="p-4">
            {
                noticias.map((noticia, index) => {

                    return (
                        <div className="mb-3" key={"crrsoel" + index} onClick={() => linkpara(noticia.idNoticia)}>

                            <div className="row p-2" id="row-noticias2">
                                <div className="col-md-2 " id="col-img-noticia2">
                                    <img src={urlImgs + "/Noticia/img/" + noticia.foto} id="img-titulo-noticia2" className="" alt="..." />
                                </div>
                                <div className="col-md-10" id="col-noticia-texto2">
                                    <h1 className="text-center" id="noticia-titulo">{noticia.titulo}</h1>
                                    <p id="txt-resumonoticia" className="ms-2">{noticia.resumo}</p>
                                </div>
                            </div>




                        </div>
                    )
                })
            }
        </div>
    );
}

export default ListaNoticiasInicial;