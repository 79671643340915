function DropProfissoes(props) {
    return (
        <>
        <label htmlFor={"cmb"+props.label.trim()} className="txt-roxo">{props.label}</label>
        <select className="form-select" aria-label="Default select example" id={"cmb"+props.label.trim()} value={props.value} onChange={(e) => props.setValue(e.target.value)}>
            <option value="0">Selecione</option>
            <option value="1">Profissional de nível Superior</option>
            <option value="2">Profissional de nível Técnico</option>
        </select>
        {props.erro !== '' ?
                <> <div className="alert alert-danger" role="alert">
                    {props.erro}
                </div> </>
                :
                <></>
            }
        </>
    );
}

export default DropProfissoes;