import { useDispatch } from 'react-redux';
import { url} from '../Utils.js'
import inMemoryJWT from './inMemoryJwt';
export default class User{

    logged = 0;
    nome = '';
    email = '';
    tipo = -1;
    datanascimento = 0;
    sexo = 0;
    escolaridade = 0;
    
    termos = -1;
    receberemail = 0;
    telefone = '';
    senha = '';
    id = 0;
    cpfcnpj = '';
    fotoBase64 = '';
    foto = '';
    id2 = '';
    hora = 0;
    cep = '';
    endereco = '';
    complemento = '';
    bairro = '';
    uf = '';
    cidade = '';
    tempo_login = 0;

    constructor(nome,email,logged){
        this.nome = nome;
        this.email = email;
        this.logged = logged;
    }

    async cadUser() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: this.nome,
                email: this.email,
                senha: this.senha,
                datanascimento: this.datanascimento,
                sexo: this.sexo,
                escolaridade: this.escolaridade,
                profissao: this.profissao,
                telefone: this.telefone,
                receberemail: this.receberemail,
                tipo: this.tipo,
                senha: this.senha,
                cpfcnpj: this.cpfcnpj,
                foto: this.foto,
                cep: this.cep,
                endereco: this.endereco,
                complemento: this.complemento,
                bairro: this.bairro,
                uf: this.uf,
                cidade: this.cidade
            })
        };
        const response = await fetch(url.concat('/User/cadUsers.php'), requestOptions);
        const jsonresp = await response.json();

        return jsonresp;
    }

    async cadUserAdmin(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: this.nome,
                email: this.email,
                senha:this.senha,
                datanascimento: this.datanascimento,
                sexo: this.sexo,
                escolaridade: this.escolaridade,
                profissao: this.profissao,
                telefone:this.telefone,
                receberemail: this.receberemail,
                tipo:this.tipo,
                senha: this.senha,
                cpfcnpj: this.cpfcnpj,
                foto: this.foto,
                idUser:idUser,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/User/cadUsersAdmin.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;
    }

    async recuperarSenha(email){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email
            })
        };
        const response = await fetch(url.concat('/User/recuperarsenha.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp.success;
    }


    async login(email,senha){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email: email, senha: senha })
        };
        const response = await fetch('/User/login.php',requestOptions);
        const jsonresp = await response.json();
        this.logged = jsonresp.id > 0;
        if (this.logged){
            this.id = jsonresp.id;
            this.nome = jsonresp.nome;
            this.email = jsonresp.email;
            this.profissao = jsonresp.profissao;
            this.receberemail = jsonresp.receberemail;
            this.sexo = jsonresp.sexo;
            this.telefone = jsonresp.telefone;
            this.termos = jsonresp.termos;
            this.tipo = jsonresp.tipo;
            this.cpfcnpj = jsonresp.cpfcnpj;
            this.foto = jsonresp.foto;
            this.tempo_login = Date.now();
        }

    }

    async loginJWT(email,senha){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email: email, senha: senha })
        };
        
        const response = await fetch(url.concat('/User/loginJWT.php'),requestOptions);
        const jsonresp = await response.json();
        this.logged = jsonresp.id != -1;
        
        if (this.logged){
            this.id2 = jsonresp.id;
            this.id = 1;
            this.nome = jsonresp.nome;
            this.email = jsonresp.email;
            this.profissao = jsonresp.profissao;
            this.receberemail = jsonresp.receberemail;
            this.sexo = jsonresp.sexo;
            this.telefone = jsonresp.telefone;
            this.termos = jsonresp.termos;
            this.tipo = jsonresp.tipo;
            this.cpfcnpj = jsonresp.cpfcnpj;
            this.foto = jsonresp.foto;
            this.hora = Date.now();
            inMemoryJWT.setRefreshTokenEndpoint(url.concat('/utils/refresh_token.php'));
            inMemoryJWT.setToken(jsonresp.jwt,jsonresp.tokenExpiry);
            inMemoryJWT.setTipo_user(jsonresp.tipo);
        }

    }

    async trocarSenha(senhaAntiga,novaSenha){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: this.id, novaSenha: novaSenha,senha:senhaAntiga })
        };
        const response = await fetch(url.concat('/User/trocarSenha.php'),requestOptions);
        const jsonresp = await response.json();
        return jsonresp.success;
    }

    async enviarFoto(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                foto: this.fotoBase64
            })
        };
        const response = await fetch(url.concat('/User/saveFoto.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp.nome;
    }

    async verificarInscricao(cpfcnpj){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({cpfcnpj: cpfcnpj,data: Math.floor(Date.now() / 1000)})
        };
        const response = await fetch('/User/consultaInscricao.php',requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;
    }
    async verificarInscricaoID(idCliente){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idCliente})
        };
        const response = await fetch('/User/consultaInscricaoID.php',requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;
    }

    async getvalidade(idUser) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ idUser: idUser })
        };
        const response = await fetch('/User/getValidade.php', requestOptions);
        const jsonresp = await response.json();
        if (jsonresp.validade == 0) {
            return "Válido";
        }
        var utcSeconds = jsonresp.validade;
        var dataCad = new Date(0);
        dataCad.setUTCSeconds(utcSeconds);
        return dataCad.toLocaleDateString();
    }

    async setContratoGerado(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser:this.id})
        };
        const response = await fetch('/User/setContratoGerado.php',requestOptions);
        const jsonresp = await response.json();
        return jsonresp;
    }

    async assinarContrato(jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser:this.id2,
            jwt:jwt})
        };
        const response = await fetch('/User/assinarContrato.php',requestOptions);
        const jsonresp = await response.json();
        return jsonresp;
    }

    async getByCpf(cpfcnpj){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({cpfcnpj: cpfcnpj})
        };
        const response = await fetch('/User/getByCpf.php',requestOptions);
        const jsonresp = await response.json();
        this.nome = jsonresp.nome;
        this.id2 = jsonresp.idUser;
        
        this.foto = jsonresp.foto;
        return jsonresp;
    }
    async getTipoUser(id){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id})
        };
        const response = await fetch(url+'/User/getTipoUser.php',requestOptions);
        const jsonresp = await response.json();
        return jsonresp.tipo;
    }
    async getUser(){
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ title: this.nome })
        };
        const response = await fetch(url.concat('/User/teste.php'),requestOptions);
        const jsonresp = await response.json();
        

    }

    async updateUser(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: this.id})
        };
        const response = await fetch('/User/updateUser.php',requestOptions);
        const jsonresp = await response.json();
        this.logged = jsonresp.id > 0;
        if (this.logged){
            this.id = jsonresp.id;
            this.nome = jsonresp.nome;
            this.email = jsonresp.email;
            this.profissao = jsonresp.profissao;
            this.receberemail = jsonresp.receberemail;
            this.sexo = jsonresp.sexo;
            this.telefone = jsonresp.telefone;
            this.termos = jsonresp.termos;
            this.tipo = jsonresp.tipo;
            this.cpfcnpj = jsonresp.cpfcnpj
        }
    }

    async getClientes(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/User/getClientes.php'),requestOptions);
        const jsonresp = await response.json();
        const lista = jsonresp.lista;
        let retorno = [];
        for (var i = 0; i < lista.length; i++){
            var associado = new User();
            associado.nome = lista[i].nome;
            associado.email = lista[i].email;
            associado.telefone = lista[i].telefone;
            associado.foto = lista[i].foto;
            
            retorno.push(associado);
        }   
        console.log(retorno);
        return retorno;
        
    }

    async getClientesCPF(cpf,idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                cpf:cpf,
                idUser: idUser,
                jwt:jwt
            })
        };
        const response = await fetch('/User/getClientesCPF.php',requestOptions);
        const jsonresp = await response.json();
        return jsonresp;
    }
    

    print(){
        console.log(this.nome);
    }

    

}