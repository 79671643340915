import React, { useState } from 'react';
import Footer from '../../components/footer';

import Navbar from '../../components/navbar';
import ListaNoticiaLista from "./components/listaNoticiasLista/listaNoticiaLista";


function ListaNoticiaScreen() {

    return (
        <>
            <div className="row">
                <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
                <div className="col-10 p-0">
                    <div className='card'>
                        <Navbar />

                        <div className="row p-4">
                            {/* <div className="col-sm-3  d-none d-sm-block"></div> */}
                            <div className="col-sm-12" align="center">
                                <ListaNoticiaLista />
                            </div>
                            {/* <div className="col-sm-3  d-none d-sm-block"></div> */}
                        </div>
                        <Footer/>
                    </div>
                </div>
                <div className="col-sm-1" id="ladodireito"></div>
            </div>
        </>
    );
}

export default ListaNoticiaScreen;