import './detalhesnoticia.css';

import React, { useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import Noticia from '../../data/noticia';

import { Link, NavLink, useParams, Navigate } from 'react-router-dom';
import { urlImgs } from '../../Utils';
import Footer from '../../components/footer';
function DetalhesNoticiaScreen(props) {

    const [noticia, setNoticia] = useState({});
    const [carregando, setCarregando] = useState(1);
    const [voltar, setVoltar] = useState(false);
    let { id } = useParams();
    function voltar2() {
        Navigate("/listanoticias")
    }
    useEffect(
        () => {
            if (carregando) {
                var noticia = new Noticia();
                noticia.getNoticiaById(id).then(
                    (noticia) => {
                        setNoticia(noticia);
                        setCarregando(0);
                    }
                );
            }


        }
    );

    if (voltar) {
        return <Navigate to="/listanoticias" />
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
                <div className="col-10 p-0">
                    <div className='card'>
                        <Navbar />
                        {carregando === 0 ?

                            <div className="row justify-content-center mt-4">
                                <div className="col-sm-3 d-none d-sm-block"></div>
                                <div className="col-sm-6">
                                    <div className="cartao card pt-4 px-2">
                                        <img className="card-img-top pb-5" src={urlImgs + "/Noticia/img/" + noticia.foto} alt="Imagem" />
                                        <h1 className="txt-verde mb-5">{noticia.titulo}</h1>
                                        <h4 className="text-justify mt-3 mb-5">{noticia.descricao}</h4>
                                        <button type="button" className="btn btn-primary botao-card mt-4" onClick={() => setVoltar(true)}>Voltar</button>
                                        <hr className="rounded divisao mt-4"></hr>

                                    </div>
                                </div>
                                <div className="col-sm-3 d-none d-sm-block"></div>

                            </div>

                            :
                            <>
                                <div className="flex mx-auto mt-4">
                                    <div className="spinner-border text-primary mx-auto" role="status">
                                        <span className="sr-only mx-auto">Loading...</span>
                                    </div>
                                </div>
                                <div className="flex mx-auto mt-4">
                                    <h2 className="txt-roxo">Carregando, Aguarde um segundo.</h2>
                                </div>
                            </>
                        }
                        <Footer/>
                    </div>
                </div>
                <div className="col-sm-1" id="ladodireito"></div>
            </div>
        </>
    );
}

export default DetalhesNoticiaScreen;