import React from 'react';
import './cardassociado.css';
function CardAssociado(props) {

    return (
        
        <div className="" key={"card_associado_lista2"+props.index}>
            <div className="border border-primary" key={"card_associado_lista21"+props.index}>
                <div className='row ms-1 ' key={"card_associado_lista123"+props.index}>
                    <div className='col-6' id="coluna-lista">
                        <h6 className="card-title txt-verde"><strong className="txt-verde">{props.nome}</strong></h6>
                    </div>
                    <div className='col-3' id="coluna-telefona-lista-associados">
                        <h6 className="card-text" id="telefone-lista-associado">{props.telefone}</h6>

                    </div>
                    <div className='col-3' id="col-email-associado">
                        <h6 className="card-text" id="email-lista-associados">{props.email}</h6>
                    </div>
                </div>


            </div>

        </div>
    );
}
export default CardAssociado;