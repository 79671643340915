import React, { Component } from 'react';
import {url} from '../../../Utils.js';

class DropTipo extends Component{
    state ={
        tipos: [],
        selectedTipo:0,
        validationError: ''
    }

    handleSelectTipo = (e) =>{
        this.setState({selectedTipo: e.target.value,
            validationError: e.target.value === 0 
            ? "Você deve selecionar um Tipo!"
        : ""});
        this.props.selectedTipo(e.target.value);
    }

    componentDidMount(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email:''})
        };

        fetch(url+"/Noticia/getTipoNoticia.php",requestOptions)
        .then((response) => {
            return response.json();
        })
        .then(data => {
            
            let tiposFromApi = data.map(tipo =>{
                return {value: tipo.id, display: tipo.nome}
            });
            this.setState({
                tipos:[{value: 0,display:'(Selecione)'}].concat(tiposFromApi)
            });
        }).catch( error =>{
            
        });
    }

    render() {
        return (
          <div>
            <select onChange={(e) => this.handleSelectTipo(e)} value={this.props.selectedTipo2}>
              {this.state.tipos.map((tipo) => <option key={tipo.value} value={tipo.value}>{tipo.display}</option>)}
            </select>
          </div>
        )
      }
}

export default DropTipo;