import Navbar from "../../components/navbar";
import ModalAviso from '../../components/avisos';
import { useEffect, useState } from "react";
import { store } from '../../store/userStore';
import inMemoryJwt from '../../data/inMemoryJwt';
import Noticia from '../../data/noticia';
import DropTipo from '../cadnoticia/dropboxtipo';
import ImageUploader from 'react-images-upload';
import { useParams } from "react-router-dom";
import { urlImgs } from "../../Utils";
import Footer from "../../components/footer";
function EditarNoticiaScreen(props) {

    const [tipo, SetTipo] = useState(0);
    const [descricao, setDescricao] = useState('');
    const [titulo, setTitulo] = useState('');
    const [resumo, setResumo] = useState('');
    const [links, setLinks] = useState('');
    const [imagem, setImagem] = useState();
    const [imgPath, setImgPath] = useState();
    const [carregando, setCarregando] = useState();
    const [imgUrl, setImgUrl] = useState('');
    const [novaImg, setNovaImg] = useState(false);

    const [msgModal, setMsgModal] = useState('');
    const [tituloModal, setTituloModal] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const state = store.getState();

    let { id } = useParams();

    async function onDrop(picture, pictureURL) {
        //setImgUrl(pictureURL);
        setImagem(picture);
        setImgPath(pictureURL);
        setNovaImg(true);
        //loadimage();
    }

    async function enviarFoto() {
        var noticia = new Noticia();
        noticia.fotoBase64 = imgPath[0];
        var resposta = await noticia.enviarFoto(state.user.id2, inMemoryJwt.getToken());
        if (resposta.success > 0) {
            return resposta.nome;
        } else {
            setTituloModal('Erro');
            setMsgModal(resposta.error);
            setModalShow(true);
            return '$';
        }
    }

    function handleSelectTipo(tipo) {
        SetTipo(tipo);
    }

    async function salvarNoticia() {
        setCarregando(true);
        if (validar()) {
            var noticia = new Noticia();
            var fotourl = '';
            if (novaImg) {
                fotourl = await enviarFoto();
            } else {
                fotourl = imgUrl;
            }
            if (fotourl !== '$') {
                noticia.idTipo = tipo;
                noticia.titulo = titulo;
                noticia.resumo = resumo;
                noticia.descricao = descricao;
                noticia.foto = fotourl;
                noticia.links = links;
                noticia.idNoticia = id;
                let resposta = await noticia.editarNoticia(state.user.id2, inMemoryJwt.getToken());
                if (resposta.success > 0) {
                    setTitulo('');
                    setResumo('');
                    setDescricao('');
                    setLinks('');
                    setTituloModal('Aviso');
                    setMsgModal('Notícia salva com sucesso.');
                    setModalShow(true);
                } else {
                    setTituloModal('Aviso');
                    setMsgModal(resposta.error);
                    setModalShow(true);
                }
            }
        } else {
            setTituloModal('Aviso');
            setMsgModal(`Você deve preencher os seguintes campos: 
            ${erro.titulo || ''}
            ${erro.descricao || ''}
            ${erro.resumo || ''}
            ${erro.links || ''}
            ${erro.tipo || ''}
            ${erro.foto || ''}`);
            setModalShow(true);
        }
        setCarregando(false);
    }
    const erro = {
        titulo: '',
        foto: '',
        descricao: '',
        resumo: '',
        links: '',
        tipo: ''
    }
    const [erros, setErros] = useState(erro);
    function validar() {
        erro.titulo = '';
        erro.foto = '';
        erro.descricao = '';
        erro.resumo = '';
        erro.links = '';
        erro.tipo = '';
        var valido = true;

        if (titulo == null || titulo == '') {
            erro.titulo = "Título";
            valido = false;
        }
        if (descricao == null || descricao == '') {
            erro.descricao = "Descrição";
            valido = false;
        }
        if (resumo == null || resumo == '') {
            erro.resumo = "Resumo";
            valido = false;
        }
        if (tipo == null || tipo == 0) {
            erro.tipo = "Tipo";
            valido = false;
        }

        setErros(erro);
        return valido;
    }

    useEffect(() => {
        var noticia = new Noticia();
        noticia.getNoticiaById(id).then((noticia_temp) => {
            setDescricao(noticia_temp.descricao);
            setTitulo(noticia_temp.titulo);
            setResumo(noticia_temp.resumo);
            setLinks(noticia_temp.links);
            SetTipo(noticia_temp.idTipo);
            setImgUrl(noticia_temp.foto);
        });
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
                <div className="col-10 p-0">
                    <div className='card'>
                        <Navbar />
                        <div className="row justify-content-center">
                            <div className="col-sm-6">

                                <div className="container mt-4 align-items-center">
                                    <h1 className="txt-roxo p-2 text-center">Editar Notícia</h1>
                                    <form className="form">
                                        <ImageUploader
                                            withIcon={true}
                                            buttonText='Escolha uma imagem'
                                            onChange={onDrop}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                            maxFileSize={5242880}
                                            withPreview={true}
                                            label='Tamanho máximo: 5MB'
                                            singleImage={true}

                                        />
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Imagem Cadastrada</label>
                                            <img src={urlImgs + "/Noticia/img/" + imgUrl} height={40} width={40} />
                                        </div>
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Título da Notícia</label>
                                            <input className="form-control" type="text" placeholder="Notícia X" value={titulo} onChange={(e) => setTitulo(e.target.value)}></input>
                                        </div>
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Breve Descrição</label>
                                            <textarea className="form-control" placeholder="Breve descrição" value={resumo} onChange={(e) => setResumo(e.target.value)}></textarea>
                                        </div>
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Notícia Completa</label>
                                            <textarea className="form-control" placeholder="Notícia Completa" value={descricao} onChange={(e) => setDescricao(e.target.value)}></textarea>
                                        </div>
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Link separados por ;</label>
                                            <input className="form-control" type="text" placeholder="Notícia X" value={links} onChange={(e) => setLinks(e.target.value)}></input>
                                        </div>
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Tipo</label>
                                            <DropTipo selectedTipo={handleSelectTipo} selectedTipo2={tipo} />
                                        </div>
                                        <div className="pt-2">
                                            {
                                                carregando ? <button type="button" className="btn btn-primary"><div className="spinner-border spinner-border-sm text-light" role="status">

                                                </div></button>
                                                    : <button type="button" className="btn btn-primary" onClick={salvarNoticia}>Salvar</button>
                                            }

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
                <div className="col-sm-1" id="ladodireito"></div>
            </div>
            <ModalAviso msg={msgModal} titulo={tituloModal} show={modalShow} onHide={() => setModalShow(false)} />
        </>
    );
}

export default EditarNoticiaScreen;