import React from "react";
import './footer.css';

function Footer() {

  return (
    <div>
                            <hr className="hr-footer" />
                            <div className="container container-footer">
                                <div className="row justify-content-between">
                                    <div className="col-sm-12 col-md-4">
                                        <h4 className="titulo-footer">Endereço</h4>
                                        <p className="text-justify">Clinical Center Praça da Bandeira, 465, sala 601. Aracaju - SE</p>
                                        <h6 className="titulo-footer">CNPJ</h6>
                                        <p className="text-justify">33.989.468/0013-43</p>
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <div className="container container-footer">
                                            <h4 className="titulo-footer">Contato</h4>
                                            <p className="text-left"><a href="https://wa.me/5579991392182"><i className="fas fa-phone-alt"></i>(79) 9 9139-2182</a></p>
                                            <p className="text-left"><a href="https://wa.me/5579999452841"><i className="fas fa-phone-alt"></i>(79) 9 9945-2841</a></p>
                                            <a className="text-left" href="mailto: abense2022@gmail.com"><i className="fas fa-envelope mr-1"/> abense2022@gmail.com</a>
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-end align-items-end">
                                <div className="col-4  d-none d-sm-block ">

                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-4 text-center">
                                    <ul className="social-icons text-center mt-3">
                                        <li><a className="facebook" href="https://www.facebook.com/abensergipe/" target="_blank"><i className="fab fa-facebook-square fa-2x mt-2"></i></a></li>
                                        {/* <li><a className="twitter" href="#"><i className="fab fa-twitter-square fa-2x mt-2" target="_blank"></i></a></li> */}
                                        <li><a className="dribbble" href="https://www.instagram.com/abennacional/" target="_blank"><i className="fab fa-instagram-square fa-2x mt-2"></i></a></li>
                                    </ul>
                                    <p className="copyright-text align-text-bottom">Desenvolvido por
                                        <a href="mailto: hbrsoftware@gmail.com">HBRSoftware</a> 2022
                                    </p>
                                </div>
                                <div className="col-4  d-none d-sm-block text-right">
                                    {/* <img src="image/bg_botom_direita.png" className="img-bgfooter" alt="" /> */}
                                </div>
                            </div>
                        </div>
  )
}

export default Footer;