import React, { Component } from 'react';
import TipoUser from '../../../data/tipouser';


class DropTipoUser extends Component{

    state = {
        tipos: [],
        selectedTipo:0,
    }

    handleSelectTipo = (e) =>{
        this.setState({selectedTipo: e.target.value});
        this.props.selectedTipoUser(e.target.value);
    }

    componentDidMount(){
        var tiposusers = new TipoUser();
        if (this.props.userAdmin)
            tiposusers.getAllTipos().then((response)=>{
                let tipoFromApi = response.map((tipo) =>{
                    return {value: tipo.id, display: tipo.descricao}
                });
                this.setState({
                    tipos: [{value:0,display:'(selecione)'}].concat(tipoFromApi)
                });
            });
        else{
            tiposusers.getTiposAberto().then((response)=>{
                let tipoFromApi = response.map((tipo) =>{
                    return {value: tipo.id, display: tipo.descricao}
                });
                this.setState({
                    tipos: [{value:0,display:'(selecione)'}].concat(tipoFromApi)
                });
            });
        }
    }

    render()
    {
    return(
            <div>
                <select onChange={(e) => this.handleSelectTipo(e)}>
                    {this.state.tipos.map((tipo) => <option key={tipo.value} value={tipo.value}>{tipo.display}</option>
                    )}
                </select>
            </div>
    );}
}

export default DropTipoUser;