

import React from 'react';
import { Link } from 'react-router-dom';
import TipoUser from '../../../data/tipouser';
import "./cardcurso.css";
function CardCurso2(props) {

    return (
        <div className="card mb-2 ms-2 me-2 cardcurso">
            <div className="card-body p-2">
                <div className='row'>
                    <div className='col-sm-4' id="col-img-top-curso">
                        <img src={props.foto} className="card-img-top" alt="..." id="card-img-top-curso" />
                    </div>
                    <div className='col-sm-8'>
                        <h4 className="card-title mt-4 txt-verde"><strong className="txt-verde">{props.titulo}</strong></h4>
                        <p className="card-text text-start">{props.descricao}</p>
                        <p className="card-text text-start">Carga horária de {props.ch} horas.</p>
                        <div className='text-start'>
                            <p className="card-text">De: {props.datai} até {props.dataf}</p>
                            <p className="card-text">Começando às {props.horai}, finalizando às {props.horaf}</p>
                            <p className="card-text">Vagas {props.vagas}, Inscritos {props.inscritos}</p>
                        </div>
                        <div className="card-body text-center">
                            {/* <button className="btn btn-primary"><Link to={'/cursodetalhe/' + props.id} className="nolink text-center texto-botao">Leia mais</Link></button> */}
                            {
                                props.inscrito == 0 ?

                                    props.vagas > props.inscritos ?
                                        <button className="btn btn-primary ms-1 nolink" onClick={props.inscrever}>Faça sua inscrição</button>
                                        :
                                        <></>

                                    :
                                    <button className="btn btn-primary nolink ms-1" onClick={props.desinscrever}>Cancelar sua inscrição</button>
                            }
                            {
                                props.tipoUser == TipoUser.Tipos.ADMIN ?
                                    <div className='mt-2'>
                                        <button className="btn btn-primary botao-card"><Link to={'/editarcurso/' + props.id} className="nolink text-center texto-botao">Editar</Link></button>
                                        <button className="btn btn-primary botao-card ms-1 nolink" onClick={props.perguntaExcluir}>Excluir</button>
                                        <button className="btn btn-primary botao-card ms-1"><Link to={'/listainscritos/' + props.id} className="nolink text-center texto-botao">Visualizar Inscritos</Link></button>
                                    </div>
                                    : <></>
                            }
                        </div>
                    </div>
                </div>



            </div>

        </div>
    );
}

export default CardCurso2;