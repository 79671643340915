import './App.css';
import {BrowserRouter, Route,Router,Routes} from 'react-router-dom';
import TelaInicial2 from './view/inicial2';
import { Provider } from 'react-redux';
import {store,persistor} from '../src/store/userStore';
import { PersistGate } from 'redux-persist/integration/react';
import CadNoticiaScreen from './view/cadnoticia';
import CadCurso from './view/cadcurso';
import ListaNoticiaScreen from './view/listanoticias';
import DetalhesNoticiaScreen from './view/detalhesNoticia';
import ListaCursosScreen from './view/listacursos';
import CadUserScreen from './view/cadUser';
import EditarNoticiaScreen from './view/editarnoticia';
import EditarCurso from './view/editarcurso';
import TrocarSenhaScreen from './view/trocarSenha';
import ListaInscritosCursoScreen from './view/listainscritoscurso';
import QuemSomosScreen from './view/quemsomos';
import ListaAssociadosScreen from './view/listaassociados';
import DetalheCursosScreen from './view/detalhesCurso';

function App() {
  return(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<TelaInicial2/>}/>
            <Route path="/cadnoticia" element={<CadNoticiaScreen/>}/>
            <Route path="/cadcurso" element={<CadCurso/>}/>
            <Route path="/editarcurso/:id" element={<EditarCurso/>}/>
            <Route path='/listanoticias' element={<ListaNoticiaScreen/>}/>
            <Route path='listacursos' element={<ListaCursosScreen/>}/>
            <Route path='/cursodetalhe/:id' element={<DetalheCursosScreen />}/>
            <Route path='/noticiadetalhe/:id' element={<DetalhesNoticiaScreen />}/>
            <Route path='/editarnoticia/:id' element={<EditarNoticiaScreen />}/>
            <Route path='/caduser' element={<CadUserScreen/>}/>
            <Route path='/trocasenha' element={<TrocarSenhaScreen/>}/>
            <Route path='/listainscritos/:id' element={<ListaInscritosCursoScreen/>}/>
            <Route path='/quemsomos' element={<QuemSomosScreen/>}/>
            <Route path='/listaassociados' element={<ListaAssociadosScreen/>}/>
          </Routes>  
        </BrowserRouter>
       
      </PersistGate>
    </Provider>
  );
}

export default App;
