import './listaNoticias.css';
import React, { useEffect, useState } from 'react';
import Noticia from '../../../../data/noticia';
import CardNoticia from '../../../../components/cardNoticia';
import { urlImgs} from '../../../../Utils.js';
import SpinnerAnjus from '../../../../components/spinner';
import { store } from '../../../../store/userStore';
import ModalAvisoEscolha from '../../../../components/avisos-escolha';
import ModalAviso from '../../../../components/avisos';
import inMemoryJwt from '../../../../data/inMemoryJwt';

function ListaNoticiaLista(){
    

    const [carregando,setCarregando] = useState(false);
    const [noticias,setNoticias] = useState([]);

    const [showModal,setShowModal] = useState(false);
    const [tituloModal,setTituloModal] = useState('Aviso');
    const [msgModal,setMsgModal] = useState('Deseja realmente excluir esta notícia?');
    const [msgAvisoModal,setMsgAvisoModal] = useState('');
    const [tituloAvisoModal,setTituloAvisoModal] = useState('');
    const [modalAvisoShow,setModalAvisoShow] = useState(false);

    const [idNoticia,setIdCurso] = useState(-1);

    const [apagado,setApagado] = useState(0);
    const state = store.getState();


    async function simExcluir(){
        setCarregando(true);
        setShowModal(false);
        var noticia = new Noticia();
        noticia.idNoticia = idNoticia;
        let resposta = await noticia.deleteNoticia(state.user.id2,inMemoryJwt.getToken());
        if (resposta.success > 0){
            setMsgAvisoModal('Notícia deletada com sucesso!');
            setTituloAvisoModal('Aviso');
            setModalAvisoShow(true);
            setApagado(apagado+1);
        } else{
            setMsgAvisoModal(resposta.error);
            setTituloAvisoModal('Aviso');
            setModalAvisoShow(true);
        }
       
        setCarregando(false);
    }
    function naoExcluir(){
        
        setShowModal(false);
    }

    function showPerguntaExcluir(id){
        setShowModal(true);
        setIdCurso(id);
        
    }

    useEffect(()=>{
        setCarregando(true);
        var noticia = new Noticia();
        noticia.getAll().then(data =>{
            setNoticias(data);
            setCarregando(false);
            
        }).catch (error =>{
            setCarregando(false);
        });
    },[apagado]);

   

   
    return (
        <div>
            {
                carregando ?
                    <span className="align-middle spiner-container">
                        <SpinnerAnjus />
                    </span>
                    :
                    <div>
                        {
                            noticias.map((noticia) => <CardNoticia
                                key={noticia.idNoticia}
                                foto={urlImgs + "/Noticia/img/" + noticia.foto}
                                titulo={noticia.titulo}
                                resumo={noticia.resumo}
                                tipo={noticia.tipoNome}
                                id={noticia.idNoticia}
                                tipoUser={state.user.tipo}
                                perguntaExcluir={() => showPerguntaExcluir(noticia.idNoticia)}
                            />)
                        }
                    </div>
            }
            <ModalAvisoEscolha
                show={showModal}
                onHide={()=>setShowModal(false)}
                titulo={tituloModal}
                msg={msgModal}
                sim={simExcluir}
                nao={naoExcluir}
            />
            <ModalAviso 
                msg={msgAvisoModal} 
                titulo={tituloAvisoModal} 
                show={modalAvisoShow} 
                onHide={() => setModalAvisoShow(false)} 
            />
        </div>
        
    );
    
}

export default ListaNoticiaLista;