import './spinner.css';

import React from 'react'

function SpinnerAnjus() {

    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-grow azulclaro mr-1" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow  mr-1 roxoanjus" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow  mr-1 verdeanjus" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow  mr-1 azulescuro" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}

export default SpinnerAnjus;