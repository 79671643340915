import { useEffect, useState } from "react";
import ImageUploader from 'react-images-upload';
import inMemoryJwt from '../../data/inMemoryJwt';
import ModalAviso from '../../components/avisos';
import Curso from "../../data/curso";
import { store } from "../../store/userStore";
import Navbar from "../../components/navbar";
import { useParams } from "react-router-dom";
import { toDataYYYYYMMDD, urlImgs } from "../../Utils";
import Footer from "../../components/footer";
function EditarCurso() {

    const [msgModal, setMsgModal] = useState('');
    const [tituloModal, setTituloModal] = useState('');
    const [modalShow, setModalShow] = useState(false);

    const [descricao, setDescricao] = useState('');
    const [titulo, setTitulo] = useState('');
    const [datai, setDatai] = useState(0);
    const [horaf, setHoraf] = useState("");
    const [dataf, setDataf] = useState(0);
    const [horai, setHorai] = useState("");
    const [ch, setCH] = useState(0);
    const [tipo, setTipo] = useState(-1);
    const [vagas, setVagas] = useState(0);
    const [imagem, setImagem] = useState();
    const [imgPath, setImgPath] = useState();
    const [imgUrl, setImgUrl] = useState('');
    const [novaImg, setNovaImg] = useState(false);

    const [carregando, setCarregando] = useState();


    const state = store.getState();
    let { id } = useParams();

    async function onDrop(picture, pictureURL) {
        setImagem(picture);
        setImgPath(pictureURL);
        setNovaImg(true);
        //loadimage();
    }

    async function enviarFoto() {
        var curso = new Curso();
        curso.fotoBase64 = imgPath[0];
        var resposta = await curso.enviarFoto(state.user.id2, inMemoryJwt.getToken());
        if (resposta.success > 0) {
            return resposta.nome;
        } else {
            setTituloModal('Erro');
            setMsgModal(resposta.error);
            setModalShow(true);
            return '$';
        }
    }

    async function editarCurso() {
        //setCarregando(true);

        if (validar()) {
            var curso = new Curso();
            var fotourl = '';
            if (novaImg) {
                fotourl = await enviarFoto();
            } else {
                fotourl = imgUrl;
            }
            if (fotourl !== '$') {

                curso.titulo = titulo;
                curso.descricao = descricao;
                curso.foto = fotourl;
                curso.ch = ch;
                curso.datai = datai;
                curso.horai = horai;
                curso.horaf = horaf;
                curso.dataf = dataf;
                curso.tipo = tipo;
                curso.idCurso = id;
                curso.vagas = vagas;
                let resposta = await curso.editarCurso(state.user.id2, inMemoryJwt.getToken());
                if (resposta.success > 0) {
                    setTitulo('');
                    setDescricao('');
                    setCH(0);
                    setDatai(0);
                    setDataf(0);
                    setVagas(0);
                    setTituloModal('Aviso');
                    setMsgModal('Curso cadastrado com sucesso.');
                    setModalShow(true);
                } else {
                    setTituloModal('Aviso');
                    setMsgModal(resposta.error);
                    setModalShow(true);
                }
            }
        } else {
            setTituloModal('Aviso');
            setMsgModal(`Você deve preencher os seguintes campos: 
            ${erro.titulo || ''}
            ${erro.descricao || ''}
            ${erro.ch || ''}
            ${erro.datai || ''}
            ${erro.horai || ''}
            ${erro.dataf || ''}
            ${erro.horaf || ''}
            ${erro.tipo || ''}
            ${erro.vagas || ''}
            ${erro.foto || ''}`);
            setModalShow(true);
        }
        setCarregando(false);
    }

    function setCHFiltro(value) {
        if (value >= 0) {
            setCH(value);
        } else {
            setCH(0);
        }
    }

    const erro = {
        titulo: '',
        foto: '',
        descricao: '',
        ch: '',
        datai: '',
        horai: '',
        dataf: '',
        horaf: '',
        tipo: '',
        vagas: ''
    }
    const [erros, setErros] = useState(erro);
    function validar() {
        erro.titulo = '';
        erro.foto = '';
        erro.descricao = '';
        erro.dataf = '';
        erro.datai = '';
        erro.ch = '';
        erro.tipo = '';
        erro.vagas = '';
        var valido = true;

        if (titulo == null || titulo == '') {
            erro.titulo = "Título";
            valido = false;
        }
        if (descricao == null || descricao == '') {
            erro.descricao = "Descrição";
            valido = false;
        }
        if (ch == null || ch == 0) {
            erro.ch = "Carga Horária";
            valido = false;
        }
        if (datai == null || datai == 0) {
            erro.datai = "Data Inicial";
            valido = false;
        }
        if (dataf == null || dataf == 0) {
            erro.dataf = "Data Final";
            valido = false;
        }
        if (dataf < datai) {
            erro.dataf = "Data final deve ser maior que inicial.";
            valido = false;
        }
        if (horai == null || horai == 0) {
            erro.horai = "Hora inicial";
            valido = false;
        }
        if (horaf == null || horaf == 0) {
            erro.horaf = "Hora Final";
            valido = false;
        }
        if (horaf < horai) {
            erro.horaf = "Hora final menor que hora inicial"
            valido = false;
        }

        if (tipo == null || tipo == -1) {
            erro.tipo = "Escolha Curso ou Evento";
            valido = false;
        }
        if (vagas == null || vagas < 1) {
            erro.vagas = "Defina a quantidade de vagas";
            valido = false;
        }
        setErros(erro);
        return valido;
    }

    useEffect(() => {
        var curso = new Curso();
        curso.getCursoById(id).then((curso_temp) => {
            setDescricao(curso_temp.descricao);
            setTitulo(curso_temp.titulo);
            setCH(curso_temp.ch);
            setDatai(toDataYYYYYMMDD(curso_temp.datai));
            setDataf(toDataYYYYYMMDD(curso_temp.dataf));
            setHorai(curso_temp.horai);
            setHoraf(curso_temp.horaf);
            setImgUrl(curso_temp.foto);
            setTipo(curso_temp.tipo);
            setVagas(curso_temp.vagas);
        });
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
                <div className="col-10 p-0">
                    <div className='card'>
                        <Navbar />
                        <div className="row justify-content-center">
                            <div className="col-sm-6">

                                <div className="container mt-4 align-items-center">
                                    <h1 className="txt-roxo p-2 text-center">Editar Cursos e Eventos</h1>
                                    <form className="form">
                                        <div className="text-center">
                                            <div className="form-check form-check-inline" >
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="0" onChange={(e) => setTipo(e.target.value)} />
                                                <label className="form-check-label" htmlFor="inlineRadio1">Curso</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="1" onChange={(e) => setTipo(e.target.value)} />
                                                <label className="form-check-label" htmlFor="inlineRadio2">Evento</label>
                                            </div>
                                        </div>
                                        <ImageUploader
                                            withIcon={true}
                                            buttonText='Escolha uma imagem'
                                            onChange={onDrop}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                            maxFileSize={5242880}
                                            withPreview={true}
                                            label='Tamanho máximo: 5MB'

                                        />
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Imagem Cadastrada</label>
                                            <img src={urlImgs + "/curso/img/" + imgUrl} height={40} width={40} />
                                        </div>
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Título do Curso</label>
                                            <input className="form-control" type="text" placeholder="Curso X" value={titulo} onChange={(e) => setTitulo(e.target.value)}></input>
                                        </div>
                                        <div className="form-group p-2">
                                            <label className="txt-roxo">Descrição</label>
                                            <textarea className="form-control" placeholder="Descrição do curso" value={descricao} onChange={(e) => setDescricao(e.target.value)}></textarea>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group p-2">
                                                    <label className="txt-roxo">Carga horária</label>
                                                    <input className="form-control" type="number" placeholder="Xh" value={ch} onChange={(e) => setCHFiltro(e.target.value)}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group p-2">
                                                    <label className="txt-roxo">Vagas</label>
                                                    <input className="form-control" type="number" placeholder="X" value={vagas} onChange={(e) => setVagas(e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group p-2">
                                                    <label className="txt-roxo">Data de Início</label>
                                                    <input className="form-control" type="date" placeholder="Data Inicial" value={datai} onChange={(e) => setDatai(e.target.value)}></input>
                                                </div>

                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group p-2">
                                                    <label className="txt-roxo">Hora de Início</label>
                                                    <input className="form-control" type="time" placeholder="Hora Inicial" value={horai} onChange={(e) => setHorai(e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group p-2">
                                                    <label className="txt-roxo">Data de Fim</label>
                                                    <input className="form-control" type="date" placeholder="Data Final" value={dataf} onChange={(e) => setDataf(e.target.value)}></input>
                                                </div>

                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group p-2">
                                                    <label className="txt-roxo">Hora de Fim</label>
                                                    <input className="form-control" type="time" placeholder="Hora Final" value={horaf} onChange={(e) => setHoraf(e.target.value)}></input>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="pt-2">
                                            {
                                                carregando ? <button type="button" className="btn btn-primary"><div className="spinner-border spinner-border-sm text-light" role="status">

                                                </div></button>
                                                    : <button type="button" className="btn btn-primary" onClick={editarCurso}>Salvar</button>
                                            }

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                        <Footer/>
                    </div>

                </div>
                <div className="col-sm-1" id="ladodireito"></div>
            </div>
            <ModalAviso msg={msgModal} titulo={tituloModal} show={modalShow} onHide={() => setModalShow(false)} />
        </>
    );

}

export default EditarCurso;