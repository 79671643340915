import './cardNoticia.css';

import React from 'react';
import {Link} from 'react-router-dom';
import TipoUser from '../../data/tipouser';

function CardNoticia(props){

    return(
        <div className="card mt-2 cardnoticia">
            <div className="card-body p-2">
                <div className='row'>
                    <div className='col-sm-4'>
                    <img src={props.foto} className="card-img-top" alt="..." />
                    </div>
                    <div className='col-sm-8'>
                    <h4 className="card-title mt-4 txt-verde"><strong className="txt-verde">{props.titulo}</strong></h4>
                <p className="card-text justificado">{props.resumo}</p>
                <div className="card-body text-center">
                    
                    <div className='row'>
                        <div className='col'>
                        <button className="btn btn-primary botao-card"><Link to={'/noticiadetalhe/'+props.id} className="nolink text-center texto-botao">Leia mais</Link></button>
                    {
                        props.tipoUser == TipoUser.Tipos.ADMIN ?
                        <>
                            <button className="btn btn-primary botao-card ms-1"><Link to={'/editarnoticia/'+props.id} className="nolink text-center texto-botao">Editar</Link></button>
                            <button className="btn btn-primary botao-card nolink ms-1" onClick={props.perguntaExcluir}>Excluir</button>
                        </>
                        :
                        <></>
                    }
                        </div>
                    </div>
                    
                </div>
                    </div>
                </div>
                
                
            </div>
            
        </div>
    );
}

export default CardNoticia;