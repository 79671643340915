import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModalAviso from '../../components/avisos';
import ModalAvisoEscolha from '../../components/avisos-escolha';
import Footer from '../../components/footer';

import Navbar from '../../components/navbar';
import SpinnerAnjus from '../../components/spinner';
import Curso from '../../data/curso';
import inMemoryJwt from '../../data/inMemoryJwt';
import { store } from '../../store/userStore';
import { toDataShort, urlImgs } from '../../Utils';
import CardCurso2 from '../listacursos/components/carcurso2';



function DetalheCursosScreen() {


    const [curso, setCurso] = useState(new Curso());
    const [carregando, setCarregando] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [tituloModal, setTituloModal] = useState('Aviso');
    const [msgModal, setMsgModal] = useState('Deseja realmente excluir este evento/curso?');
    const [msgAvisoModal, setMsgAvisoModal] = useState('');
    const [tituloAvisoModal, setTituloAvisoModal] = useState('');
    const [modalAvisoShow, setModalAvisoShow] = useState(false);
    const state = store.getState();
    const [idCurso, setIdCurso] = useState(-1);
    const [apagado, setApagado] = useState(0);

    let { id } = useParams();

    async function simExcluir() {
        setCarregando(true);
        setShowModal(false);
        var curso = new Curso();
        curso.idCurso = idCurso;
        let resposta = await curso.deleteCurso(state.user.id2, inMemoryJwt.getToken());
        if (resposta.success > 0) {
            setMsgAvisoModal('Curso deletado com sucesso!');
            setTituloAvisoModal('Aviso');
            setModalAvisoShow(true);
            setApagado(apagado + 1);
        } else {
            setMsgAvisoModal(resposta.error);
            setTituloAvisoModal('Aviso');
            setModalAvisoShow(true);
        }

        setCarregando(false);
    }
    function naoExcluir() {

        setShowModal(false);
    }

    function showPerguntaExcluir(id) {
        setShowModal(true);
        setIdCurso(id);

    }

    useEffect(() => {
        setCarregando(true);
        var cursos = new Curso();

        cursos.getCursoById(id).then((curso) => setCurso(curso));
        setCarregando(false);
    }, [apagado]);
    return (
        <>
            <div className="row">
                <div className="col-sm-1 mr-0" id="ladoesquerdo"></div>
                <div className="col-10 p-0">
                    <Navbar />

                    <div className="row">
                        <div className="col-md-1  d-none d-md-block"></div>
                        <div className="col-md-10 mt-4" align="center">
                            <h2>Cursos & Eventos Ofertados</h2>
                            {
                                carregando ?
                                    <span className="align-middle spiner-container">
                                        <SpinnerAnjus />
                                    </span>
                                    :
                                    <CardCurso2
                                        titulo={curso.titulo}
                                        descricao={curso.descricao}
                                        id={curso.id}
                                        datai={toDataShort(curso.datai)}
                                        dataf={toDataShort(curso.dataf)}
                                        horai={curso.horai}
                                        horaf={curso.horaf}
                                        ch={curso.ch}
                                        key={"cardcursos" + curso.id}
                                        foto={urlImgs + "/curso/img/" + curso.foto}
                                        tipoUser={state.user.tipo}
                                        perguntaExcluir={() => showPerguntaExcluir(curso.id)}
                                    />
                            }

                        </div>
                        <div className="col-md-1  d-none d-md-block"></div>
                        <Footer/>
                    </div>
                </div>
                <div className="col-sm-1" id="ladodireito"></div>
            </div>
            <ModalAvisoEscolha
                show={showModal}
                onHide={() => setShowModal(false)}
                titulo={tituloModal}
                msg={msgModal}
                sim={simExcluir}
                nao={naoExcluir}
            />
            <ModalAviso
                msg={msgAvisoModal}
                titulo={tituloAvisoModal}
                show={modalAvisoShow}
                onHide={() => setModalAvisoShow(false)}
            />
        </>
    );
}

export default DetalheCursosScreen;