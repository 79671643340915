import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Curso from "../../data/curso";

import { urlImgs } from "../../Utils";
import "./listacursoinicial.css";

function ListaCursoInicial(props) {


    const [cursos, setCursos] = useState([]);

    useEffect(() => {
        var cursos_aux = new Curso();
        cursos_aux.get5().then((retorno) => {
            setCursos(retorno);
        });
    }, []);

    let navigate = useNavigate();


    const linkpara = (link) => {
        navigate('/cursodetalhe/' + link);
    }


    return (
        <>


            <div className="p-4">
                {
                    cursos.map((curso, index) => {

                        return (
                            <div className="mb-3" key={"crrsoelcurso" + index} onClick={() => linkpara(curso.idCurso)}>
                                <div className="row p-2" id="row-cursos2">
                                    <div className="col-md-2" id="col-img-curso2">
                                        <img src={urlImgs + "/curso/img/" + curso.foto} id="img-titulo-curso2" className="img-fluid d-block w-100" alt="..." />
                                    </div>
                                    <div className="col-md-10" id="col-curso-texto2">
                                        <h1 className="text-center" id="curso-titulo">{curso.titulo}</h1>
                                        <p id="txt-resumocurso" className="ms-2">{curso.descricao}</p>
                                    </div>
                                </div>



                            </div>
                        )
                    })
                }


            </div>
        </>
    );

}
export default ListaCursoInicial;